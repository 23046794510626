@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  // --primaryClr: #efa206;
  --primaryClr: #ed9e0e;
  --secondaryClr: #32383e;
  --secondarClr1: #2f619e;
  --headClr: #22262a;
  --bodyClr: #0b0d0e;
  --whiteClr: #ffffff;
  --blackClr: #000;
  --background: #eee;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #faa8ba;
  --siteRedClr: #d9534f;
  --siteGreenClr: #27ae60;
}
.light-mode:root {
  --primaryClr: #ffb80c;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

body {
  background: var(--background);
  color: var(--whiteClr);
  font-size: 14px;
  // font-family: "Roboto", sans-serif;
  font-family: Tahoma, Helvetica, sans-serif;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
.btn-primary {
  background: var(--primaryClr);
  color: var(--blackClr);
  border-color: var(--primaryClr);
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
  padding: 0 16px;
  height: 36px;
  &:hover,
  &:focus {
    background: var(--primaryClr) !important;

    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-primary {
  border-color: var(--primaryClr);
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 6px;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--primaryClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--primaryClr);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--blackClr);
  }
}
img {
  width: 100%;
  height: auto;
}
input,
input.form-control {
  border-radius: 0;
  border-color: var(--primaryClr);
  font-size: 14px;
  font-weight: 300;
  color: #3b5160;
  &:focus {
    box-shadow: none !important;
  }
  &::placeholder {
    color: #7e97a7;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--headClr) inset !important;
  -webkit-text-fill-color: var(--whiteClr) !important;
  outline: 0 !important;
  border: 0 !important;
}

body {
  .theme-btn {
    display: none;
    .theme-icon {
      width: 25px;
      height: 25px;
      border: 1px solid var(--whiteClr);
      border-radius: 50%;
      text-align: center;
      line-height: 13px;
      padding: 4px;
      .dark_theme {
        display: none;
      }
    }
  }
  &.light-mode {
    .theme-btn {
      .theme-icon {
        border-color: var(--whiteClr);
        .dark_theme {
          display: block;
        }
        .light_theme {
          display: none;
        }
      }
    }
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  background: #000;
  background-image: linear-gradient(180deg, #383838 0%, #010101 100%);
  width: 100%;
  z-index: 999;
  @media (max-width: 767.98px) {
    background-image: linear-gradient(180deg, #474747 0%, #070707 100%);
  }
  .container-fluid {
    max-width: calc(100% - 40px);
    padding: 0;
    @media (min-width: 1400px) {
      min-width: 1350px;
    }
    @media (max-width: 767.98px) {
      max-width: calc(100% - 0px);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
    .col-12 {
      @media (max-width: 767.98px) {
        padding-left: 0;
      }
    }
  }
  .top-header {
    width: 100%;
    height: 74px;
    margin: 0 auto;
    padding-top: 10px;
    @media (max-width: 767.98px) {
      width: 100vw;
      height: 14.6666666667vw;
      padding: 2.6666666667vw 0 2.6666666667vw;
    }
  }
  .middle-part {
    .navbar {
      .navbar-collapse {
        background-color: transparent !important;
      }
    }
  }
  .leftbar_toggle {
    .btn {
      background: transparent;
      border: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 15px;
        height: 15px;
        display: block;
        fill: var(--whiteClr);
      }
      &:hover,
      &:focus {
        background: transparent !important;
      }
    }
  }
  .logo {
    a {
      display: block;
      max-width: 75px;
      @media screen and (max-width: 767.98px) {
        width: 15vw;
        margin: -1.0666666667vw 0 0 1.8666666667vw;
      }
    }
  }
  .navbar {
    padding: 0;
    .navbar-collapse {
      border-radius: 22px;
      .navbar-nav a {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.4px;
        height: 40px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        text-transform: uppercase;
        &.exchange-nav div {
          display: none;
          display: flex;
          margin-right: 5px;
          -webkit-animation: sports-book-bouncing 3s linear infinite alternate;
          animation: sports-book-bouncing 3s linear infinite alternate;
          svg,
          img {
            display: none;
            -webkit-animation: sports-book-rotate 3s linear infinite alternate;
            animation: sports-book-rotate 3s linear infinite alternate;
            margin-right: 0px;
          }
        }

        svg,
        img {
          fill: var(--whiteClr);
          width: 23px;
          height: 23px;
          margin-right: 5px;
          // display: none;
        }
        &:hover,
        &.active {
          background: var(--secondaryClr);
          color: var(--whiteClr);
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    @media only screen and (max-width: 767.98px) {
      width: 55%;
      padding-right: 1.8666666667vw;
    }
    .my-info {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    .my-bets {
      @media only screen and (min-width: 768px) {
        display: none;
      }
      a {
        background-color: rgba(255, 255, 255, 0.1);
        color: #ffb200;
        font-weight: bold;
        // min-width: 29.3333333333vw;
        min-width: 22.3333333333vw;
        max-width: 31.2vw;
        height: 9.3333333333vw;
        line-height: 5.3333333333vw;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.8666666667vw 0 1.3333333333vw;
        margin-left: -1px;
        border: 0.2666666667vw solid var(--blackClr);
        border-left-width: 0;
        border-radius: 0 1.0666666667vw 1.0666666667vw 0;
        box-shadow: inset 0 0.2666666667vw 0 0 rgba(255, 255, 255, 0.3);
        img {
          width: 5.3333333333vw;
          height: 5.0666666667vw;
          margin-right: 1.3333333333vw;
        }
      }
    }
    form {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 767.98px) {
        width: 100%;
      }
      .form-group {
        margin: 0 5px 2px 0;
        position: relative;
        @media only screen and (max-width: 767.98px) {
          margin: 0;
        }
        &.user {
          display: flex;
          align-items: center;
          img {
            width: 13px;
            height: auto;
            margin-right: 8px;
          }
        }
        .form-control {
          background: var(--whiteClr) !important;
          color: var(--bodyClr) !important;
          font-size: 12px;
          font-family: Tahoma, Helvetica, sans-serif;
          width: 130px;
          height: 25px;
          margin: 0;
          padding: 5px;
          outline: 0 !important;
          border: 0px #aaa solid;
          border-radius: 4px;
          box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.5);
          box-sizing: border-box;
          // box-sizing: border-box;
          &:focus {
            border-width: 1px;
            border-color: #2789ce;
            box-shadow: 0 0 4px 2px rgba(114, 187, 239, 0.8);
          }
          &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
          }
        }
        .validation {
          position: absolute;
          top: 0px;
          right: -10px;
          width: 55px;
          height: 16px;
          color: var(--blackClr);
          font-size: 15px;
          font-weight: 900;
        }
      }
      .btns {
        margin-left: 2px;
        @media only screen and (max-width: 767.98px) {
          width: 100%;
          flex-direction: row-reverse;
        }
        .form-group {
          @media only screen and (max-width: 767.98px) {
            width: 100%;
          }
          button {
            color: var(--whiteClr) !important;
            font-size: 12px;
            font-weight: bold;
            width: 80px;
            height: 25px;
            line-height: 13px;
            text-decoration: none;
            padding: 6px 11px;
            border: 0;
            border-radius: 4px;
            @media only screen and (max-width: 767.98px) {
              font-size: 3.4666666667vw;
              width: 100%;
              max-width: 28.8vw;
              height: 8.5333333333vw;
              line-height: 8.5333333333vw;
              padding: 0 !important;
              border: 0.2666666667vw solid transparent;
              border-radius: 1.0666666667vw;
              box-shadow: inset 0 0.2666666667vw 0 0 rgba(255, 255, 255, 0.5);
            }
            &.login-bnt {
              background-color: #e83523;
              background-image: linear-gradient(
                -180deg,
                #f72424 0%,
                #bb1c00 100%
              ) !important;
              @media only screen and (max-width: 767.98px) {
                margin-left: 0.5333333333vw !important;
                border-color: #710b0b !important;
              }
              img {
                width: 10px;
                height: 11px;
                margin: -2px 0 0 6px;
                @media only screen and (max-width: 767.98px) {
                  width: 4.2666666667vw;
                  height: 4.2666666667vw;
                  margin-right: 0.5333333333vw;
                }
                &.d_sm_none {
                  @media only screen and (max-width: 767.98px) {
                    display: none;
                  }
                }
              }
            }
            &.signup {
              background-color: #666;
              background-image: linear-gradient(
                -180deg,
                #666666 0%,
                #333333 100%
              ) !important;
              @media only screen and (max-width: 767.98px) {
                border-color: #000 !important;
              }
            }
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .funds {
      display: flex;
      align-items: center;
      margin-right: 15px;
      border: 1px solid var(--blackClr);
      @media (max-width: 767.98px) {
        margin-right: 2.1333333333vw;
        border: 0;
        border-radius: 1.0666666667vw;
      }
      ul {
        background: rgba(255, 255, 255, 0.1);
        height: 24px;
        line-height: 24px;
        padding: 0 7px;
        margin-right: 0;
        cursor: pointer;
        border-radius: 4px 0 0 4px;
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
        @media (max-width: 767.98px) {
          font-size: 2.6666666667vw;
          height: 9.3333333333vw;
          line-height: 3.2vw;
          padding: 0 1.0666666667vw 0 1.8666666667vw;
          border: 0.2666666667vw solid var(--blackClr);
          border-radius: 1.0666666667vw 0 0 1.0666666667vw;
        }
        li {
          display: flex;
          align-items: center;
          margin: 0 7px 0 0 !important;
          position: relative;
          a {
            color: var(--primaryClr);
            display: flex;
            align-items: center;
            > div {
              &:not(:last-child) {
                margin-right: 7px !important;
              }
            }
            &:not(:last-child) {
              margin-right: 7px !important;
            }
            span {
              @media (max-width: 767.98px) {
                font-size: 2.6666666667vw !important;
              }
              &:first-child {
                font-size: 12px;
                letter-spacing: -0.008em;
                opacity: 0.7;
              }
              &:last-child {
                font-size: 12px;
                font-weight: bold;
                @media (max-width: 767.98px) {
                  color: #ffb200;
                  padding-left: 5px;
                }
              }
              &.bal-text {
                @media (max-width: 767.98px) {
                  display: none;
                }
              }
            }
          }
          &.balance {
            @media (max-width: 767.98px) {
              display: grid;
              line-height: 3.7vw;
            }
          }
          &.bal-count {
            color: #ffb600;
            font-weight: bold;
            font-size: 11px;
            height: 16px;
            line-height: 14px;
            letter-spacing: 1px;
            padding: 0 8px 0 7px;
            margin-right: 0 !important;
            border: 1px solid rgba(255, 182, 0, 0.5);
            border-radius: 3px;
            @media only screen and (max-width: 767.98px) {
              height: 3.7333333333vw;
              font-size: 2.9333333333vw;
              line-height: 3.7333333333vw;
              border-width: 0.2666666667vw;
              border-radius: 0.8vw;
              padding: 0 2.1333333333vw;
            }
            span {
              font-size: 10px;
              @media only screen and (max-width: 767.98px) {
                font-size: 2.6666666667vw;
                font-weight: bold;
                margin-left: 0.2666666667vw;
              }
            }
          }
        }
      }
      .refresh {
        // background: rgba(255, 255, 255, 0.1);
        background: #525252;
        width: 25px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #000;
        border-radius: 0 4px 4px 0;
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
        @media only screen and (max-width: 767.98px) {
          background: rgba(255, 255, 255, 0.1);
          font-weight: bold;
          width: auto;
          height: 9.3333333333vw;
          line-height: 5.3333333333vw;
          display: table;
          padding: 1.6vw 1.8666666667vw;
          border: 0.2666666667vw solid var(--blackClr);
          border-left-width: 0;
          border-radius: 0 1.0666666667vw 1.0666666667vw 0;
          box-shadow: inset 0 0.2666666667vw 0 0 rgba(255, 255, 255, 0.3);
        }
        img {
          width: 14px;
          height: 14px;
          @media only screen and (max-width: 767.98px) {
            width: 5.0666666667vw;
            height: 5.0666666667vw;
          }
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        top: -4px;
        background-color: var(--whiteClr);
        width: 222px;
        padding: 0;
        border-radius: 4px;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
        .user {
          color: #3b5160;
          font-size: 12px;
          font-weight: bold;
          line-height: 27px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          margin-bottom: 0;
          border-bottom: 1px solid #7e97a7;
          .gmt {
            font-size: 11px;
            padding: 0 5px;
            margin-left: auto;
            border-left: 1px solid #c5d0d7;
          }
        }
        .dropdown-item {
          font-size: 12px;
          line-height: 25px;
          padding: 0 10px;
          border-bottom: 1px solid #e0e6e6;
          &.logout {
            padding: 7px 10px 5px;
            border-bottom: 0;
            span {
              background-color: #7e97a7;
              color: var(--whiteClr);
              font-weight: bold;
              text-transform: uppercase;
              display: inherit;
              text-align: center;
              line-height: 25px;
              padding: 0 10px;
              border-radius: 4px;
              img {
                width: 10px;
                height: 11px;
              }
            }
          }
          &:hover,
          &.active,
          &:active {
            background: #eff2f2;
          }
        }
      }
    }
    ul {
      display: flex;
      align-items: center;
      margin-right: 5px;
      li {
        margin: 0 5px;
        position: relative;
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .menu-btn {
      background: rgba(255, 255, 255, 0.1);
      color: var(--primaryClr);
      font-weight: 400;
      width: 112px;
      height: 27px;
      line-height: 25px;
      padding: 0;
      border: 1px solid var(--blackClr);
      border-radius: 4px;
      box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.3);
      @media only screen and (max-width: 767.98px) {
        font-weight: bold;
        width: auto;
        height: 9.3333333333vw;
        line-height: 5.3333333333vw;
        padding: 1.6vw 1.8666666667vw;
        margin-left: 0;
        border-width: 0.2666666667vw;
        border-radius: 1.0666666667vw;
        box-shadow: inset 0 0.2666666667vw 0 0 rgba(255, 255, 255, 0.3);
      }
      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.1) !important;
        color: var(--primaryClr) !important;
        border-color: var(--blackClr) !important;
      }
      &.dropdown-toggle {
        &::after {
          position: relative;
          right: -8px;
          top: 1.5px;
          border-top: 0.425em solid;
        }
        &::after,
        .my-account {
          @media only screen and (max-width: 767.98px) {
            display: none;
          }
        }
        .setting {
          @media only screen and (min-width: 768px) {
            display: none;
          }
          width: 5.0666666667vw;
          height: 5.0666666667vw;
          display: block;
          text-align: end;
          img {
            @media only screen and (max-width: 767.98px) {
              width: 100%;
              height: auto;
              margin-right: 0;
            }
          }
        }
      }
      img {
        width: 13px;
        height: 12px;
        margin: -2px 5px 0 -8px;
        fill: var(--primaryClr);
      }
    }
  }
  &.aftrlgn {
    .headerRight {
      width: 100%;
      padding-right: 0;
    }
  }
  .bottom_head {
    background: #ffcc2e;
    background: linear-gradient(180deg, #ffcc2e 0%, #ffbd14 100%);
    ul {
      display: flex;
      align-items: center;
      overflow-x: auto;
      li {
        display: inline-block;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        a {
          color: var(--blackClr);
          font-size: 12px;
          font-weight: 600;
          cursor: pointer;
          display: block;
          padding: 6px 10px;
          position: relative;
          @media screen and (max-width: 575.98px) {
            padding: 2px 5px 2px;
          }
          &.active {
            background: #ffdc7a;
            box-shadow: inset 0 0px 5px 0 rgba(83, 33, 33, 0.5);
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }
        }
        &.dark_menu-li {
          a {
            background: linear-gradient(#4b4b4b, #1e1e1e);
            color: var(--whiteClr);
            padding: 4px 15px 4px 20px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 29px;
              height: 29px;
              background: url("./assets/images/menu-tagnew.png") no-repeat;
            }
            &::after {
              content: "";
              display: inline-block;
              position: relative;
              top: 2px;
              left: 6px;
              width: 18px;
              height: 18px;
              background: url("./assets/images/dark-menu-casino.png") no-repeat;
            }
          }
        }
        .live-match {
          position: absolute;
          top: -6px;
          right: 3px;
          min-width: 33px;
          height: 12px;
          background: var(--whiteClr);
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 3px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
          img {
            width: 18px;
            height: 8px;
            padding: 0 2px;
            margin: 0 auto;
            animation: twinkling 1.4s infinite ease;
          }
          span {
            background-image: linear-gradient(180deg, #fb3434 0%, #e80505 100%);
            color: var(--whiteClr);
            padding: 0 3px;
            border-radius: 0 3px 3px 0;
          }
        }
      }
    }
  }
  .header-leftpart {
    display: flex;
    align-items: center;
  }
}

.logo,
.footer-logo {
  img {
    &.light-theme {
      display: none;
    }
  }
}
.light-mode {
  .logo,
  .footer-logo {
    img {
      &.light-theme {
        display: block;
      }
      &.dark-theme {
        display: none;
      }
    }
  }
}
.light-mode {
  .offcanvas {
    .offcanvas-body {
      .social_links {
        li {
          svg {
            filter: brightness(0);
          }
        }
      }
    }
  }
}

.back-link {
  font-size: 10px;
  color: var(--primaryClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}
.offcanvas {
  border: none !important;
  z-index: 999999999;
}
.offcanvas-header {
  background: var(--headClr);
  color: var(--whiteClr);
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--bodyClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--whiteClr);
  }
  .user-info {
    color: var(--whiteClr);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: bold;
    }
    a {
      color: var(--whiteClr);
    }
  }
  .btn-close {
    opacity: 1;
  }
}
.offcanvas-body {
  background: var(--bodyClr);
  .balance {
    font-size: 16px;
    color: var(--whiteClr);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: var(--whiteClr);
  }
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
  .menu-items {
    border-top: 1px solid var(--whiteClr);
    margin: 30px -1rem 0 -1rem;
    li {
      border-bottom: 1px solid var(--whiteClr);
      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: var(--whiteClr);
        svg {
          margin-right: 15px;
          width: 22px;
          height: auto;
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        cursor: pointer;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.form-steps {
  text-align: center;
}
body.leftbat-opened {
  .leftbarSec {
    transform: translateX(0);
  }
}
.mt--14 {
  .leftbarSec {
    margin-top: -14px;
  }
}
.main {
  .leftbarSec {
    background: #1d1e22;
    width: 200px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (max-width: 1199.98px) {
      position: fixed;
      top: 99px;
      transform: translateX(-100%);
      transition: all 0.3s;
      height: calc(100vh - 99px);
      z-index: 99999999;
    }
    @media only screen and (max-width: 767.98px) {
      top: 66px;
      width: 70%;
      height: calc(100vh - 66px);
    }
    .games_link {
      h6 {
        color: var(--primaryClr);
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 10px;
        border-bottom: 1px solid var(--primaryClr);
      }
      ul {
        // padding: 10px 0;
        li {
          cursor: pointer;
          // border-bottom: 1px solid #333;
          background: #25262b;
          padding: 0px;
          margin: 0 0 8px;
          border-radius: 10px;
          transition: all 0.2s;
          &:hover {
            border-radius: 0 10px 10px 0;
          }
          > div {
            line-height: 35px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            position: relative;
            &.inplay {
              img {
                -webkit-animation: sports-book-bouncing 3s linear infinite
                  alternate;
                animation: sports-book-bouncing 3s linear infinite alternate;
              }
            }
            &:hover img {
              -webkit-animation: icon-animation 1.5s infinite alternate;
              animation: icon-animation 1.5s infinite alternate;
              -webkit-animation: icon-animation1 1.5s infinite alternate;
              animation: icon-animation1 1.5s infinite alternate;
            }
            &:before {
              position: absolute;
              content: "";
              top: 0;
              left: -10px;
              width: 2px;
              height: 100%;
              background: var(--primaryClr);
              opacity: 0;
              transition: all 0.3s;
            }
            &:hover {
              &:before {
                opacity: 1;
                left: 0;
              }
            }
            img {
              width: 22px;
              height: auto;
            }
            span {
              color: #f0f1f3;
              font-size: 12px;
              // font-weight: 600;
              text-transform: uppercase;
              margin-left: 10px;
              opacity: 0.8;
            }
            .sport-event-count {
              background: var(--primaryClr);
              color: var(--blackClr);
              font-size: 12px;
              margin-left: auto;
              padding: 0.25em 0.4em;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  @-webkit-keyframes icon-animation {
    to {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
    }
  }

  @keyframes icon-animation {
    to {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
    }
  }
  @-webkit-keyframes icon-animation1 {
    to {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }

  @keyframes icon-animation1 {
    to {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  }
  .middleContentSec {
    width: 100%;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 63px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
        width: calc(100% - 500px);
      }
    }
  }
  .rightbarSec {
    background: var(--secondaryClr);
    width: 400px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
    h1 {
      color: var(--primaryClr);
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    .VSlider {
      background-color: var(--headClr);
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: #ccc;
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                background: var(--primaryClr);
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: #333;
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: var(--headClr);
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #222;
                  thead {
                    background: #222;
                    display: block;
                    tr {
                      th {
                        color: var(--whiteClr);
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: var(--whiteClr);
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);
              .live_tv_box {
                height: 175px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;
    .match_info_slider {
      .info_box {
        background: #222430;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 128px;
        padding: 8px;
        margin: 0 5px;
        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          .tournament {
            color: #abb0ba;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time {
            color: var(--whiteClr);
            font-weight: 500;
          }
        }
        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .item {
            flex: 1;
            overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 28px;
              height: 28px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        background: #222430;
        height: 100%;
        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }
        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
  .popular_game_sec {
    background: transparent;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    box-shadow: 0 4px 3px rgba(255, 189, 0, 0.6);
    @media screen and (max-width: 767.98px) {
      padding: 1rem 2rem 0.5rem;
    }
    .head-popular {
      font-weight: 600;
      font-size: 1.25rem;
    }
    .game_box {
      margin: 5px;
      a {
        img {
          border-radius: 0.75rem;
        }
      }
    }
  }
  .sports_banner_sec {
    // background-color: #181818;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    // border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 767.98px) {
      padding: 0rem;
      background-color: transparent;
    }
    .img_box {
      img {
        border-radius: 1.5rem;
      }
    }
  }
  .casino_games {
    .heading {
      min-width: 20%;
      @media screen and (max-width: 767.98px) {
        margin-bottom: 1rem !important;
      }
      h5 {
        font-weight: 600;
      }
      h6 {
        font-weight: 500;
        font-size: 15px;
      }
      .btn {
        color: var(--secondaryClr);
        font-size: 16px;
        width: 90%;
        min-width: 170px;
        border-radius: 40px;
        @media screen and (max-width: 1199.98px) {
          width: 100%;
        }
        &.casino_btn,
        &.exchange_btn {
          background: #2f619e !important;
          color: var(--whiteClr);
          border-color: #2f619e;
          &:hover {
            border-color: #2f619e !important;
          }
        }
      }
    }
    .games {
      overflow: hidden;
      h5 {
        line-height: 56px;
        margin: 0 0 16px 8px;
      }
      .game-box {
        .img-box {
          position: relative;
          overflow: hidden;
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s ease;
            z-index: 99;
            .play-btn {
              background: var(--primaryClr);
              color: var(--whiteClr);
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0.25px;
              width: fit-content;
              height: 44px;
              line-height: 44px;
              padding: 0 16px;
              border-radius: 25px;
              cursor: pointer;
              opacity: 0.85;
              &:hover,
              &:focus {
                opacity: 1;
              }
            }
          }
          img {
            border-radius: 0.75rem;
            transition: all 0.3s;
          }
        }
        .game_name {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.25px;
          height: 16px;
          line-height: 16px;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 8px;
          @media only screen and (max-width: 400px) {
            max-width: 130px;
          }
        }
        &:hover {
          .img-box {
            .overlay {
              opacity: 1;
            }
            img {
              transform: scale(1.1);
            }
          }
          .game_name {
            color: var(--primaryClr);
          }
        }
      }
    }
    .slick-arrow {
      top: -35px;
      width: 40px;
      height: 40px;
      background: var(--headClr);
      border-radius: 5px;
      &:hover,
      &:focus {
        background: #4c555e;
      }
      &.slick-prev {
        left: auto;
        right: 45px;
        &:before {
          content: url("./assets/images/arrow-left.svg");
        }
      }
      &.slick-next {
        right: 0;
        &:before {
          content: url("./assets/images/arrow-right.svg");
        }
      }
      &.slick-prev,
      &.slick-next {
        &:before {
          opacity: 0.65;
        }
        &:hover,
        &:focus {
          &:before {
            opacity: 1;
          }
        }
      }
    }
    &.home_tabs_section {
      .nav-tabs {
        border: 0;
        .nav-item {
          flex: 1;
          &:first-child {
            display: none;
          }
        }
        .nav-link {
          background: #22262a;
          color: #cfd4d8;
          font-size: 11px;
          font-weight: 600;
          letter-spacing: -0.2px;
          line-height: 16px;
          // width: 68px;
          width: calc(100% - 2px);
          min-width: 68px;
          height: 52px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 32px 5px 5px;
          margin: 0 2px;
          border: 0;
          border-radius: 5px;
          position: relative;
          &:before {
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            filter: invert(93%) sepia(15%) saturate(73%) hue-rotate(164deg)
              brightness(91%) contrast(89%);
            transition: all 0.3s;
          }
          &#controlled-tab-example-tab-allGames {
            &:before {
              content: url("./assets/images/allGames-tab-icon.svg");
            }
            display: none;
          }
          &#controlled-tab-example-tab-top {
            &:before {
              content: url("./assets/images/top-tab-icon.svg");
            }
          }
          &#controlled-tab-example-tab-new {
            &:before {
              content: url("./assets/images/new-tab-icon.svg");
            }
          }
          &#controlled-tab-example-tab-roulette {
            &:before {
              content: url("./assets/images/roulette-tab-icon.svg");
            }
          }
          &#controlled-tab-example-tab-blackjack {
            &:before {
              content: url("./assets/images/blackjack-tab-icon.svg");
            }
          }
          &#controlled-tab-example-tab-baccarat {
            &:before {
              content: url("./assets/images/baccarat-tab-icon.svg");
            }
          }
          &#controlled-tab-example-tab-tvShows {
            &:before {
              content: url("./assets/images/tvShow-tab-icon.svg");
            }
          }
          &#controlled-tab-example-tab-holdem {
            &:before {
              content: url("./assets/images/holdem.svg");
            }
          }
          &#controlled-tab-example-tab-sicbo {
            &:before {
              content: url("./assets/images/sicbooo.svg");
            }
          }
          &#controlled-tab-example-tab-lottery {
            &:before {
              content: url("./assets/images/casino_lottery.svg");
            }
          }
          &:hover,
          &:focus,
          &.active {
            background: #f9fafa;
            color: #22262a;
            &:before {
              filter: invert(10%) sepia(17%) saturate(531%) hue-rotate(169deg)
                brightness(91%) contrast(88%);
            }
          }
        }
      }
      .tab-pane:not(:first-child) {
        .games {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          .game-box {
            width: calc(12.5% - 13.125px);
            @media (max-width: 1499.98px) and (min-width: 1200px) {
              // grid-template-columns: repeat(7, 1fr);
              width: calc(14.28% - 12.85px);
            }
            @media (max-width: 1199.98px) and (min-width: 992px) {
              // grid-template-columns: repeat(6, 1fr);
              width: calc(16.66% - 12.5px);
            }
            @media (max-width: 991.98px) and (min-width: 768px) {
              // grid-template-columns: repeat(4, 1fr);
              width: calc(25% - 11.25px);
            }
            @media (max-width: 767.98px) {
              // grid-template-columns: repeat(3, 1fr);
              width: calc(33.33% - 10px);
            }
            // @media only screen and (max-width: 575.98px) {
            //   grid-template-columns: repeat(2, 1fr);
            // }
          }
        }
      }
    }
  }

  .TopCategories {
    margin: 0 0px 30px;
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0;
      background: var(--secondaryClr);
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 20px;
      .nav-item {
        button,
        .anav-link {
          background-size: 30px 30px !important;
          background-position: 27px 10px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 14px;
          width: 92px;
          height: 100%;
          padding: 46px 5px 5px;
          border: 0;
          border-radius: 0;
          &.active {
            background: var(--bodyClr);
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("../public/images/sportsss.png");
          }
        }
      }
    }
  }
  .mainBanner {
    .slick-slide {
      img {
        height: auto;
        max-height: 232px;
        object-fit: cover;
        margin-top: 1px;
      }
      .content {
        bottom: auto;
        font-size: 0.85rem;
        left: 0;
        position: absolute;
        right: auto;
        text-align: left;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        z-index: 99;
        h2 {
          @media screen and (min-width: 768px) {
            font-size: 2rem;
          }
          @media screen and (min-width: 1024px) {
            font-size: 3.5rem;
          }
          @media screen and (max-width: 767px) {
            font-size: 1.15rem;
          }
          span {
            color: var(--primaryClr);
            margin: 0 8px;
          }
        }
        p {
          @media screen and (max-width: 767px) {
            font-size: 0.7rem;
          }
        }
      }
    }
    .slick-dots {
      bottom: 10px;
      text-align: center;
      padding-left: 15px;
      li {
        margin: 0;
        button {
          &::before {
            color: var(--whiteClr);
            font-size: 10px;
            opacity: 0.6;
          }
        }
      }
    }
  }
  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: var(--primaryClr);
    position: relative;
    display: inline-block;
    margin: 20px 0 10px;
    padding-bottom: 5px;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    background: #3b5160;
    padding: 22px 1rem;
    color: var(--whiteClr);
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }
    }
  }
  .account-statement {
    input,
    .form-control {
      z-index: 9 !important;
      &::placeholder {
        color: #7e97a7 !important;
      }
    }
    button.calender-icon {
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 40px;
      height: 33px;
    }
    h3 {
      color: #3b5160;
      font-size: 16px;
      font-weight: 300;
      margin: 14px 0;
      padding: 0 1rem 12px 1rem;
      border-bottom: 1px solid #7e97a7;
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      // border-top: 1px solid var(--blackClr);
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid var(--blackClr);
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;

          span:last-child {
            color: red;
            cursor: pointer;
          }
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
    table {
      thead {
        tr {
          th {
            background: #3b5160;
            color: var(--whiteClr);
            white-space: nowrap;
          }
        }
      }
      tbody {
        tr {
          td {
            background: #e0e6e6;
            color: var(--blackClr);
            white-space: nowrap;
            border-color: #7e97a7;
          }
        }
      }
    }
  }
  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
      max-width: 120px;
      width: 120px;
      height: 120px;
      border-radius: 5px;
      border: 2px solid var(--primaryClr);
    }
    span {
      color: var(--blackClr);
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .edit-btn {
    background: var(--whiteClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--whiteClr);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-info {
    th {
      background: #3b5160;
      color: var(--whiteClr);
    }
    td {
      background: #e0e6e6;
      color: var(--blackClr);
      white-space: nowrap;
      border-color: #7e97a7;
    }
    .edit-btn {
      top: 0;
      right: 0;
      button {
        width: 30px;
        height: 29px;
        display: flex;
        margin-left: 14px;
        justify-content: center;
        align-items: center;
        padding: 0;
        &:focus {
          background-image: var(--primaryClr);
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

.listings {
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    svg {
      fill: var(--whiteClr);
    }
  }
}

.steps-canvas {
  .offcanvas-header {
    background: var(--headClr);
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--whiteClr);
    }
  }
  .form-steps {
    padding: 55px 30px 0;
    .resendOTP {
      margin-right: 10px;
      padding: 6px 20px !important;
      @media only screen and (max-width: 767px) {
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--whiteClr);
    }
    p {
      font-size: 18px;
      font-weight: 300;
      color: var(--whiteClr);
    }
    input {
      background: var(--headClr);
      color: var(--whiteClr) !important;
      padding: 14px 20px;
      border-radius: 10px;
      border: 1px solid rgb(100 100 100) !important;
    }
    .icon {
      position: absolute;
      top: 12px;
      right: 15px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .btn {
      padding: 9px 30px;
    }
    .form-link {
      font-size: 14px;
      color: var(--lightClr);
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.tabs-area {
  margin-top: 10px;
  ul {
    margin: 0 -1rem;
    flex-wrap: nowrap;
    li {
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid var(--whiteClr);
      }
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: #f3f3f3 !important;
          color: #26324b !important;
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--whiteClr);
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      .top-area {
        background: var(--whiteClr);
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    form {
      padding: 55px 30px 0;
      text-align: center;
      input {
        background: var(--headClr);
        border-radius: 10px;
        border-color: var(--headClr);
        padding: 14px 20px;
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 115px;
      background: var(--whiteClr);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-height: 40px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}

.transfer_info_page {
  .transfer_details {
    background: url("./assets/images/deposit-bg.jpg") var(--secondaryClr);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 10px;
    overflow: hidden;
    z-index: 9;
    form {
      .form-group {
        background: linear-gradient(to right, #10091d, #000d1b) !important;
        border: 1px solid #013f48;
        border-radius: 5px;
        padding-top: 7px;
        .form-label {
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 3px;
        }
        .form-control {
          padding: 10px 15px;
          border: 0;
          border-top: 1px solid rgb(1, 63, 72, 0.35);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 2px 0 rgba(0, 0, 0, 0.12),
            inset 0 1px rgba(255, 255, 255, 0.04);
        }
      }
    }
  }
  .deposit_img {
    img {
      animation: moving 3s infinite linear;
      @keyframes moving {
        0% {
          left: 0;
        }
        50% {
          left: 0;
          -webkit-transform: translateY(15px);
          transform: translateY(15px);
        }
        100% {
          left: 0;
        }
      }
    }
  }
}

.upi-list {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 7px;
      width: 50px;
      border: none;
    }
  }
}

.sports_page_banner {
  .slick-slider {
    img {
      border-radius: 5px;
      height: 210px;
      object-fit: cover;
    }
    .slick-dots {
      bottom: 8px;
      li {
        height: 3px;
        button {
          height: 3px;
          padding: 0;
          &:before {
            background: rgb(135 135 135 / 70%);
            color: transparent;
            width: 25px;
            height: 3px;
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &:before {
              background: var(--primaryClr);
            }
          }
        }
      }
    }
  }
}

.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--headClr);
    // margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        color: var(--whiteClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;
        position: relative;
        p {
          position: absolute;
          margin: 0;
          top: -6px;
          right: -4px;
          width: 14px;
          height: 14px;
          background: #3e3d3d;
          box-shadow: 2px 5px 15px #1d1d1d;
          border-radius: 50%;
          display: flex;
          font-size: 9px;
          justify-content: center;
          align-items: center;
        }
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: var(--secondaryClr);
      border: 1px solid var(--headClr);
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid rgba(255, 184, 12, 0.1);
        background: var(--secondaryClr);
        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--whiteClr);
            cursor: pointer;
            .date_time_info {
              background: #36404d;
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;
              .date {
                color: var(--whiteClr);
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li {
            margin-right: 5px;
            width: 45px;
            height: 30px;
            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: #101010;
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: linear-gradient(
        to right,
        #f7a204 0,
        #ffc230 15%,
        #ffc230 30%,
        #f7a204 55%,
        #f7a204 100%
      );
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 110%;
          left: -5%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: rgb(237, 33, 58);
          font-size: 12px;
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: #111;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      cursor: pointer;

      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: #222;
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      width: 120px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      width: 110px;
      text-align: left;
      justify-content: left;
    }
  }
  .title {
    background: var(--headClr);
    padding: 5px 0rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      width: 130px;
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid var(--headClr);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 7px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 24px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.footer {
  padding: 30px 18px 70px 18px;
  margin-top: 20px;
  .play_live_info {
    background: rgba(34, 38, 42, 0.5);
    padding: 16px 16px 8px;
    margin-bottom: 16px;
    border-radius: 5px;
    .info_block {
      margin-bottom: 40px;
    }
    h1 {
      color: #cfd4d8;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 8px;
    }
    h2 {
      color: #cfd4d8;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin-bottom: 8px;
    }
    p {
      color: #abb2ba;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.25px;
      margin-bottom: 16px;
    }
    a {
      color: #f9fafa;
      text-decoration: underline;
      padding: 0 3px;
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    ul,
    ol {
      list-style-position: inside;
      padding: 0;
      margin: 0 0 16px 10px;
      li {
        color: #abb2ba;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.25px;
      }
    }
    .show_less_text {
      height: 44px;
      line-height: 44px;
      .btn {
        background: var(--secondaryClr);
        color: var(--primaryClr);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.25px;
        text-transform: uppercase;
        line-height: 35px;
        padding: 2px 10px;
        border: 0;
        outline: 0;
        box-shadow: none;
        transition: all 0.3s;
        &:hover,
        &:focus {
          color: #f9fafa;
        }
      }
    }
  }
  .static_links {
    padding: 16px 0;
    margin-bottom: 16px;
    h5 {
      color: #f9fafa;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 16px;
    }
    ul {
      li {
        a {
          color: #cfd4d8;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.25px;
          line-height: 16px;
          display: inline-block;
          padding: 12px 0;
          &:hover,
          &:focus {
            color: #f9fafa;
          }
        }
      }
    }
  }
  .license_sec {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    margin-bottom: 16px;
    overflow-x: scroll;
    img {
      width: auto;
      // max-width: 40px;
    }
  }
  .copyright_info {
    color: #abb2ba;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    .copyright_year {
      margin-bottom: 8px;
    }
  }
  .providers_sec {
    background: var(--blackClr);
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 80%;
      width: 1px;
      background-color: #7e7e7e;
      left: 50%;
      top: 10%;
      transform: translate(-50% - -50%);
    }
    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      width: 100%;
      justify-content: center;
      text-align: center;
      h5 {
        font-size: 22px;
        font-weight: bold;
      }
      @media screen and (max-width: 1199.98px) {
        margin: 0 auto;
      }
      p {
        span {
          color: var(--primaryClr);
        }
      }
    }
    .providers_logo {
      // width: calc(100% - 300px);
      &.payment_logo {
        a {
          width: 120px !important;
        }
      }
      @media screen and (max-width: 1199.98px) {
        width: calc(100% - 0px);
        margin-bottom: 20px !important;
      }
      .logo_box {
        margin: 3px;
        text-align: center;
        img {
          max-width: 120px;
          padding: 3px;
          &.payment-height {
            max-width: 55px;
          }
        }
      }
    }
  }
  .providers_img {
    text-align: center;
    a {
      display: inline-block;
      padding: 0 18px;
      max-width: 130px;
      img {
        filter: contrast(0.1) brightness(1000);
      }
    }
  }
  .helpline {
    background: #222;
    max-width: 300px;
    text-align: center;
    padding: 10px;
    margin: 10px auto;
    border: 1px solid #333;
    border-radius: 10px;
    h3 {
      font-size: 21px;
      font-weight: 700;
    }
    a {
      color: var(--primaryClr);
      font-size: 15px;
      font-weight: 700;
    }
  }
  p {
    color: var(--whiteClr);
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
  }
  .betfair {
    max-width: 110px;
    height: auto;
    filter: invert(1);
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 46px;
        }
      }
    }
  }
  .footer-links {
    margin-top: 12px;
    ul {
      text-align: center;
      padding: 7px 0;
      li {
        font-size: 13px;
        font-weight: 300;
        display: inline-block;
        border-right: 1px solid #fff;
        &:last-child {
          margin-right: 0;
          border-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: #fff;
          padding: 0 15px;
          &:hover,
          &:focus {
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.agent-head.crypto-head {
  background: transparent;
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  background: #222430;
  border-radius: 6px;
  padding: 10px;
  margin-top: 15px;
  .social-icon {
    width: 4%;
    margin: 0 1%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  // padding-top: 50px;

  // &.ezugi {
  //   background: rgb(35, 31, 32);
  // }
  &.live {
    background: var(--headClr);
    color: var(--whiteClr);
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);
      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // @media screen and (min-width: 750px) {
  //   padding-top: 60px;
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 70px;
  // }

  scroll-snap-type: mandatory;

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}

.form-control {
  background: transparent !important;
  color: #3b5160 !important;
  &:focus {
    border-color: var(--primaryClr);
  }
  &::placeholder {
    color: #7e97a7;
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.betslip-content.inline_betslip {
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning {
    p {
      line-height: 1.3;
      margin-bottom: 0;
      @media only screen and (max-width: 767.98px) {
        font-size: 0.8rem;
      }
    }
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      li {
        width: calc(25% - 0.5rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border: 0;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--primaryClr);
          color: var(--blackClr);
          width: 100%;
          padding: 5px;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: #2ebd97;
        color: var(--whiteClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
        &:hover {
          background: #025f18;
        }
      }
    }
    .cancel-btn {
      background: var(--lightClr);
      color: var(--siteRedClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
      &:hover {
        background: var(--siteRedClr);
        color: var(--whiteClr);
      }
    }
  }
}

.betslip_popup {
  padding-left: 0 !important;
  .betslip_content {
    border-radius: 4px 4px 0 0;
    .laybet {
      background-color: rgb(164 82 100) !important;
      border-top: 3px solid #f87592 !important;
      padding: 10px 10px 25px 10px;
    }
    .backbet {
      background-color: rgb(52 85 112) !important;
      border-top: 3px solid #68bbff !important;
      padding: 10px 10px 25px 10px;
    }
    input {
      background-color: #fff !important;
      color: #000 !important;
    }
    .auto-stack {
      ul li {
        width: calc(10% - 0.5rem);
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 0;
        background: var(--primaryClr);
        border: 0 !important;
        .stack-btn {
          background: var(--primaryClr);
          color: var(--blackClr);
          width: 100%;
          padding: 5px;
        }
      }
    }
  }

  .modal-body {
    background: var(--headClr);
    border: 1px solid #222;
    border-top-width: 1px;
    border-radius: 4px 4px 0 0;
    padding: 0;
    .table-responsive {
      padding: 0;
    }
    table {
      margin-bottom: 0;
      thead {
        background: #222;
        tr {
          th {
            border: 0;
            color: #fff;
          }
        }
      }
      tbody {
        tr {
          border-color: #333;
        }
        td {
          color: #fff;
          &.yes {
            color: #72bbef;
          }
          &.no {
            color: #faa8ba;
          }
        }
      }
    }
  }
  .modal-footer {
    background: #444;
    padding: 7px 10px;
    border: 0;
    .btn {
      min-width: 100px;
      margin: 0 auto;
    }
  }
  &.outer_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:nth-child(3) {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.inner_popup {
    .modal-body {
      table {
        thead {
          tr {
            th {
              @media screen and (max-width: 767.98px) {
                &:not(:first-child) {
                  min-width: 80px;
                }
                :last-child {
                  min-width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.contact-social ul {
  display: flex;
  li img {
    width: 60px;
    height: auto;
    margin: 0 15px;
  }
}
.static-content {
  margin-top: 20px;
  h3 {
    color: var(--bodyClr);
    font-size: 16px;
  }
  p {
    color: var(--blackClr);
  }
  p.privacy-points {
    margin-bottom: 9px;
    width: 20%;
    position: relative;
    border-bottom: 1px solid #fff;
    padding-left: 12px;
    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50%;
      top: 6px;
      left: 0;
    }
  }
}
.responsible-game {
  margin-top: 20px;
}
.exchange-popup {
  z-index: 99999999999;
  .modal-content {
    background: #1a1e29;
    border-radius: 0 !important;
    .modal-header,
    .modal-body,
    .modal-footer {
      border: 0;
      padding: 15px;
    }
    .btn-close {
      background-image: url(assets/images/close.svg);
      opacity: 1;
    }
    .balnace-wallet {
      display: flex;
      flex-direction: column;
      .balance-value {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        svg {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          color: var(--primaryClr);
        }
      }
    }
    .amount-input {
      form {
        margin-top: 5px;
        input {
          background: #fff !important;
          border-width: 2px;
          border-radius: 5px;
          color: #000 !important;
        }
        .exchange-button {
          display: flex;
          justify-content: space-between;
          button {
            font-size: 16px;
            padding: 6px 8px;
          }
        }
      }
    }
  }
}
input[type="date"].form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.header.beforeheader {
  button.navbar-toggler {
    display: none;
  }
}
.page-details {
  .game-points {
    background: var(--headClr);
    padding-bottom: 0.05rem;
    border-radius: 0;
    .title {
      background: var(--whiteClr);
      border-bottom: 1px solid #7e97a7;
      span {
        color: var(--blackClr);
        font-size: 13px;
        font-weight: 600;
      }
    }
    ul {
      li {
        padding: 0 0 0 0.75rem;
        border-color: #333;
        &:last-child {
          border-bottom: 0;
        }
        .team-name {
          color: #bcbcbc;
        }
        .match_odds {
          .info-block {
            &:first-child {
              background: #d7e8f4 !important;
            }
            &:nth-child(2) {
              background: #b7d5eb !important;
            }
            &:nth-child(3) {
              background: #72bbef !important;
            }
            &:nth-child(4) {
              background: #faa8ba !important;
            }
            &:nth-child(5) {
              background: #efd3d9 !important;
            }
            &:last-child {
              background: #f6e5ea !important;
            }
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
    &.bookmaker {
      background: #faf8d8;
      .game-top-area {
        .title {
          background: transparent;
          width: auto;
          border: 0;
          span {
            color: #bdc4c7;
            padding: 0;
          }
        }
        .range {
          position: relative;
          left: auto;
          transform: unset;
          background: #bed5d8;
          padding: 5px 10px;
          margin: 4px 0;
          border-radius: 5px;
        }
      }
      ul {
        background: transparent;
        li {
          .match_odds {
            @media only screen and (max-width: 767.98px) {
              width: 140;
            }
            .back_all_odds {
              background: linear-gradient(
                90deg,
                rgba(130, 183, 221, 0.15) 0%,
                rgba(130, 183, 221, 0.8) 65%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  90deg,
                  rgba(151, 199, 234, 0.7) 0%,
                  #97c7ea 65%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(3) {
                  background: #72bbef !important;
                  border: 1px solid #fff !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1),
                  &:nth-child(2) {
                    display: none !important;
                  }
                  &:nth-child(3) {
                    display: block !important;
                    width: 100%;
                  }
                }
              }
              .bookmaker_susp {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                color: var(--whiteClr);
                font-weight: bold;
                text-shadow: 0 0.2666666667vw 1.0666666667vw rgb(0 0 0 / 50%);
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  opacity: 0.8;
                }
              }
            }
            .lay_all_odds {
              background: linear-gradient(
                270deg,
                rgba(231, 170, 184, 0.15) 5%,
                rgba(231, 170, 184, 0.8) 60%
              );
              display: flex;
              padding: 0.25rem;
              @media only screen and (max-width: 767.98px) {
                background-image: linear-gradient(
                  270deg,
                  rgba(247, 205, 214, 0.75) 5%,
                  #f0c0cb 60%
                );
                width: 70px;
              }
              .info-block {
                &:nth-child(1) {
                  background: #faa9ba !important;
                  border: 1px solid #fff !important;
                  border-radius: 4px;
                  span {
                    color: var(--blackClr);
                  }
                }
                @media only screen and (max-width: 767.98px) {
                  &:nth-child(1) {
                    display: block !important;
                    width: 100%;
                  }
                  &:nth-child(2),
                  &:nth-child(3) {
                    display: none !important;
                  }
                }
              }
            }
            .info-block {
              background: transparent !important;
              margin: 0 !important;
              line-height: 27px;
              span {
                color: #59708d;
              }
              @media only screen and (max-width: 767.98px) {
                &:nth-child(1) {
                  display: block !important;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .title {
    &.market_heading {
      background: #222;
      span {
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  .header_open {
    .navbar {
      transform: translateX(0px) !important;
      .navbar-toggler {
        right: -12px !important;
      }
    }
  }
  .header {
    .navbar {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-230px);
      width: 230px;
      height: 100%;
      background: var(--bodyClr);
      padding: 1rem;
      z-index: 9;
      transition: all 0.3s;
      .navbar-toggler {
        position: absolute;
        top: 70px;
        right: -30px;
        background: var(--whiteClr);
        padding: 0.15rem 0.25rem;
        border-radius: 25px;
        z-index: 99;
        .navbar-toggler-icon {
          width: 20px;
          height: 22px;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .navbar-collapse {
        position: fixed;
        top: 1rem;
        left: 1rem;
        width: 200px;
        height: 100%;
        display: block !important;
        background: transparent;
        padding: 0;
        margin: 0;
        .navbar-nav {
          width: 100%;
          flex-direction: column;
          a {
            color: var(--whiteClr);
            font-size: 1em;
            text-transform: capitalize;
            width: 100%;
            height: 30px;
            line-height: 1.33;
            margin-bottom: 1.1rem;
            padding: 0;
            &:last-child {
              margin-bottom: 0;
            }
            svg,
            img {
              fill: var(--whiteClr);
              width: 20px;
              height: 20px;
              margin-right: 7px;
            }
            &:hover {
              opacity: 0.8;
            }
            &.logoImg {
              width: fit-content;
              margin-bottom: 2.25rem;
              img {
                width: 150px;
                height: auto;
                @media screen and (max-width: 767.98px) {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .cards {
    width: 100% !important;
  }
  .primary_card .stats-card {
    display: grid !important;
    ul {
      width: 100% !important;
      border-right: 0 !important;
    }
  }
  .match_info_slider .provider-imagess {
    width: 100% !important;
    img {
      width: 85% !important;
    }
  }

  .new_baneeerr {
    .slick-slide img {
      width: 300px !important;
    }
  }

  .provide {
    // flex-wrap: wrap;
    justify-content: center;
    img {
      width: 95%;
    }
  }
  .main .casino_games.home_tabs_section .nav-tabs {
    overflow-x: scroll !important;
    flex-wrap: nowrap !important;
  }
  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 20%;
      margin: 1%;
    }
  }
  .header {
    .navbar {
      .navbar-toggler {
        border-radius: 0.375rem;
        line-height: 16px;
        padding: 0 0.15rem;
        right: -37px;
        top: 16px;
      }
    }
    &.aftrlgn {
      .logo {
        margin-left: 30px;
        margin-right: 0;
        display: none;
      }
    }
  }
  .custom-tabs {
    padding-top: 0px;
    .sports-name-listing {
      ul {
        padding: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .page-details {
    .game-top-area {
      .range {
        display: none;
      }
      .date {
        display: none;
      }
    }
    .game-points {
      ul {
        li {
          display: flex;
          padding-left: 0.5rem;
          border-bottom: 1px solid #2f3940;
          .team-name {
            width: calc(100% - 150px);
            flex-direction: revert;
            justify-content: space-between;
            color: var(--whiteClr);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 0.25rem 0 0;
          }
          .status-btns {
            width: 140px;
            display: flex;
            align-items: center;
            .info-block {
              width: inherit;
              display: none;
              &:nth-child(3),
              &:nth-child(4) {
                display: flex;
                width: 50%;
              }
              &:nth-child(4) {
                border: 0;
              }
              &:first-child {
                margin-left: 0;
                display: none;
              }
            }
            .info-block.no:nth-child(2) {
              display: flex;
              width: 50%;
            }
          }
        }
      }
    }
  }
  .sports_page,
  .custom-tabs {
    .tab-pane {
      .game-listing {
        .match-info {
          display: block;
          .match-name {
            width: 100%;
          }
          .live-match {
            height: 10px;
            width: 10px;
            margin-right: 8px;
            background: #c4cfd6;
            border: 1px solid #366601;
            border-radius: 50%;
          }
          .listing {
            padding-bottom: 5px;
            li {
              width: 100%;
              margin-right: 3px;
              &:nth-child(2),
              &:nth-child(4) {
                margin-right: 6px;
              }
            }
          }
        }
        .match-listing-head {
          display: none;
        }
      }
    }
  }
}
.mybets-tabs-section {
  ul {
    width: max-content;
    display: flex;
    margin-bottom: 20px !important;
    @media only screen and (max-width: 767px) {
      overflow-x: scroll;
      width: auto;
      margin: 15px 0px !important;
    }
    li {
      background: #3b5160;
      padding: 8px 15px !important;
      border-bottom: 0 !important;
      border-right: 1px solid #545454;
      border-radius: 6px;
      white-space: nowrap;
      &.active {
        background: #3b5160;
      }
      div {
        color: var(--whiteClr);
      }
    }
  }
}

.account-statement {
  border-bottom: 1px solid #7e97a7;
  table {
    tbody {
      tr {
        td {
          span.lay {
            background: #faa8ba;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
        td {
          span.back {
            background-color: #72bbef;
            border-radius: 5px;
            font-size: 11px;
            color: #000;
            padding: 5px;
            width: 40px;
            justify-content: center;
            display: flex;
          }
        }
      }
    }
  }
}
.transfer_info_page {
  input {
    border-color: #585858 !important;
    &::placeholder {
      color: #7e97a7;
      font-weight: bold;
    }
  }
  button[type="submit"] {
    margin-top: 15px;
  }
  .upi-list {
    li {
      border: 0 !important;
      margin-top: 20px;
    }
  }
}
.panel {
  svg {
    fill: white;
  }
}

.whtasapp-all-page {
  position: fixed;
  right: 10px;
  z-index: 9;
  bottom: 125px !important;
  width: 135px;
  height: 40px;
}
.confirm-title {
  color: black;
}
.edit-btn {
  svg {
    fill: #000;
  }
}

.bookmaker {
  background: var(--headClr) !important;
  span {
    background-color: transparent !important;
    margin-left: 0 !important;
  }
  .status-btns {
    height: 44px;
    width: 408px;
  }
  .points-status {
    width: 100%;
    top: 3px !important;
    width: 100% !important;
    height: 40px !important;
    margin: 0 !important;
    &::after {
      height: 29px !important;
      top: 5px !important;
    }
  }
}

@-webkit-keyframes sports-book-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50%,
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes sports-book-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50%,
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes sports-book-bouncing {
  0% {
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  20% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  32.5% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  41% {
    -webkit-transform: translateY(-8%);
    transform: translateY(-8%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  46.5% {
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  12.5%,
  27.5%,
  37.5%,
  43.5% {
    -webkit-transform: translateY(0) scale(1.1, 0.9);
    transform: translateY(0) scale(1.1, 0.9);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.sessionmarket .game-points ul li .status-btns .info-block:nth-child(2) {
  display: flex !important;
  width: 68px !important;
}
.webslider {
  display: flex;
  .imgBox {
    margin: 0 8px;
    width: 33.33%;
  }
}

.bet-liability-markets {
  width: 110px;
  font-size: 12px;
  border-radius: 5px;
  margin: 4px 0px;
  color: var(--blackClr);
  padding: 0px 5px;
  background-color: var(--primaryClr);
}

.withdrawlist {
  table {
    tr {
      th {
        background: #3b5160;
        color: var(--whiteClr);
        white-space: nowrap !important;
      }
      td {
        background: #e0e6e6;
        color: var(--blackClr);
        white-space: nowrap !important;
        border-color: #7e97a7;
      }
    }
    tbody {
      background: #e0e6e6;
      color: var(--blackClr);
    }
  }
}
.steps-canvas .form-steps {
  .btn {
    padding: 6px 30px;
    margin: 0 6px 10px 6px !important;
    &:hover {
      color: var(--blackClr);
    }
  }
  .btn:disabled {
    background: var(--primaryClr);
    color: var(--blackClr);
    border-color: var(--primaryClr);
  }
}

.payment-info.social {
  background-color: transparent !important;
  position: fixed;
  right: 0;
  top: 30%;
  width: 70px;
  margin: -30px -1rem;
  z-index: 99;

  ul {
    flex-direction: column;
    position: relative;
    li {
      margin-bottom: 10px;
      a {
        align-items: center;
        background-color: rgb(0 0 0 / 56%);
        border: 2px solid #4a4a4a;
        border-radius: 5px;
        display: flex;
        height: 60px;
        position: relative;
        right: 15px;
        transition: all 0.3s;
        width: 70px;
        cursor: pointer;
        &:hover {
          right: 250px;
          width: 300px;
        }
        img {
          height: auto;
          margin: 0 15px;
          width: 35px;
        }
        p {
          color: #fff;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }
}
.paymentDetails {
  p {
    color: var(--blackClr);
  }
}

.changePasswordModal {
  .modal-header {
    background-color: var(--headClr);
    border: 0 !important;
    border-radius: 4px 4px 0 0;
    .btn-close {
      filter: invert(1);
    }
  }
  .modal-body {
    background-color: var(--bodyClr);
    .icon_view {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  .modal-footer {
    background-color: var(--bodyClr);
    border: 0 !important;
    border-radius: 0 0 4px 4px;
  }
}

.loaderImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  img {
    width: 150px;
  }
}

.slots-section {
  margin: 25px auto 0;
  width: 95%;
  .game-heading {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    img {
      margin-right: 10px;
      width: 30px;
    }
    span {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 15px;
    padding: 0;
    li {
      background-color: transparent;
      display: block;
      width: calc(16.66% - 12.5px);
      height: auto;
      overflow: hidden;
      padding: 0;
      position: relative;
      @media (max-width: 767.98px) and (min-width: 576px) {
        width: calc(25% - 11.25px);
      }
      @media (max-width: 575.98px) {
        width: calc(33.33% - 10px);
      }
      &:hover {
        .casino_overlay {
          inset: 0 0 0 0;
          img {
            height: 80px !important;
            width: 80px !important;
          }
        }
      }
      img {
        border-radius: 8px;
        width: 100% !important;
      }
      .casino_overlay {
        align-items: center;
        background: linear-gradient(
          0deg,
          rgb(239 110 48 / 50%),
          rgb(252 192 17 / 50%)
        );
        border-radius: 10px;
        content: "";
        display: flex;
        inset: 0 0 0 100%;
        justify-content: center;
        position: absolute;
        transition: all 0.9s;
        img {
          border-radius: 10px;
          height: 0;
          width: 0 !important;
        }
      }
    }
  }
}
.icon_view {
  // svg {
  //   cursor: pointer;
  // }
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  fill: var(--whiteClr);
}
.casino_games .slick-initialized .slick-slide {
  width: 230px !important;
}
.evolution_gamess {
  ul.nav-tabs {
    border: 0;
    li {
      width: auto;
      button {
        background-color: var(--secondaryClr);
        border-radius: 8px;
        color: #fff;
        &:hover {
          background-color: var(--primaryClr);
          color: #000;
          border: 0;
        }
        &.active {
          background-color: var(--primaryClr);
          color: #000;
          border: 0;
        }
      }
    }
  }
  svg {
    fill: #fff;
    margin-right: 8px;
  }
  ul {
    li {
      width: 19%;
    }
  }
}

.casino_games .slick-initialized .slick-slide {
  width: 300px !important;
  margin-right: 7px;
  margin-left: 7px;
}
.casino_games .slick-track {
  display: flex;
  // width: 100% !important;
}

.accountstatementModal {
  .modal-header,
  .modal-footer {
    background-color: var(--headClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background-color: #222430;
    border: 0;
    max-height: calc(100vh - 170px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #222;
    }
    &::-webkit-scrollbar-thumb {
      background: #333;
      &:hover {
        background: #444;
      }
    }
    .account-statement {
      border-bottom: 1px solid #4c4c4c;
      margin-bottom: 10px;
      padding-bottom: 10px;
      color: #fff;
      &:last-child {
        border: 0;
        margin: 0;
        padding: 0;
      }
      span {
        color: #fff;
      }
      small {
        margin-left: 8px;
        color: #fff;
      }
      .text-end {
        h5 {
          color: red;
          font-weight: bold;
        }
        small {
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }
}
.provide {
  // flex-wrap: wrap;
  justify-content: center;
}

@keyframes mirrorflip {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }
  50% {
    transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
  }
  100% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }
}
.main_new_banner {
  .child_banner {
    position: relative;
    width: 100%;
    display: inline-block;
    img:nth-child(2) {
      z-index: 9999;
      position: absolute;
      top: 0;
      animation-name: banners-move-front;
      animation-duration: 30s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      -webkit-user-select: none;
      user-select: none;
      -webkit-user-drag: none;
      animation-play-state: running;
    }
  }
}

@keyframes slide-in-from-top {
  0% {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-out-to-top {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes slide-right-to-left {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes vanishIn {
  0% {
    transform: translate(-50%, -50%) scale(2);
    transform-origin: 50% 50%;
    opacity: 0;
    filter: blur(10px);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 50% 50%;
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes textScale {
  50% {
    transform: scale(1.2);
  }
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  40% {
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes opacityIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacityOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes overlay {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes banners-move-front {
  0% {
    transform: translateZ(0) rotate(0.0001deg);
  }

  25% {
    transform: translate3d(20px, 0, 0) rotate(0.0001deg);
  }

  50% {
    transform: translateZ(0) rotate(0.0001deg);
  }

  75% {
    transform: translate3d(-20px, 0, 0) rotate(0.0001deg);
  }

  to {
    transform: translateZ(0) rotate(0.0001deg);
  }
}

@keyframes banners-move-bg {
  0% {
    transform: translateZ(0) rotate(0.0001deg);
  }

  25% {
    transform: translate3d(-20px, 0, 0) rotate(0.0001deg);
  }

  50% {
    transform: translateZ(0) rotate(0.0001deg);
  }

  75% {
    transform: translate3d(20px, 0, 0) rotate(0.0001deg);
  }

  to {
    transform: translateZ(0) rotate(0.0001deg);
  }
}

@keyframes modal-slide-up {
  0% {
    transform: translate3d(0, 50%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes modal-slide-up-outer-leave {
  0% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes explosion-pop {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  to {
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
.provider-imagess {
  width: 100% !important;
  text-align: center;
  img {
    width: 75% !important;
    margin: 0 auto;
  }
}
.gatewaySection {
  max-width: 800px;
  margin: 25px auto 15px;
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      button {
        background: var(--headClr);
        border-radius: 15px 15px 0px 0px;
        border: 0;
        width: 100%;
        color: #fff;
        &.active {
          background: var(--primaryClr);
          border-radius: 15px 15px 0px 0px;
          color: #000;
        }
      }
    }
  }
  .tab-content {
    background: var(--secondaryClr);
    padding: 20px 50px;
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        background: var(--headClr);
        border: 0.5px solid #22262a;
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;
        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          border-bottom: 0.5px solid #22262a;
          text-align: center;
          img {
            width: 80px;
          }
        }
        p {
          padding: 11px 4px;
          font-weight: 500;
          font-size: 12px;

          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
.gatewayForm {
  margin: 25px;

  input {
    border-color: #4a5d6b;
    padding: 14px 20px;
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    padding: 9px 30px;
    text-transform: uppercase;
  }
}
.new_table {
  thead {
    color: var(--whiteClr);
    th {
      background: #3b5160;
      padding: 5px;
    }
  }
  tbody {
    color: var(--whiteClr);
    tr {
      td {
        background: #e0e6e6;
        border-color: #7e97a7;
      }
    }
  }
}
.primary_card {
  border-radius: 10px;
  border: 1px solid #d58b2f;
  .stats-card {
    display: flex;
    ul {
      border-right: 1px solid #353c4b;
      width: 50%;
      &:last-child {
        border: 0;
      }
      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #353c4b;
        font-weight: 700;
        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}
.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .progress {
    background: #393939;
  }
}
.cards {
  background: #000000;
  span {
    color: orange;
    margin-left: 4px;
  }
  padding: 15px;
  border-radius: 10px;
  width: 32.33%;
  margin: 0.5%;
  // background: url(./assets/images/background.jpg);
  box-shadow: 0px 1px 10px 3px #3a3a3a70;
}
.amount_data {
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
.amount_data {
  .status {
    color: green;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .status_claimed {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_pending {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_expired {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_canceled {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}
.cancel_bonus {
  background: red;
  padding: 2px 4px;
  font-size: smaller;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
}
.cancel_bonus_modal {
  .modal-dialog {
    .modal-content {
      background: #fff;
      color: #000;
    }
  }
}
.referral_card_main {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media (max-width: 767.98px) {
    margin-top: 10px !important;
  }
  .referrals_cards {
    background: #3b5160;
    flex: 1 1;
    width: 100%;
    padding: 1.5rem;
    border-radius: 5px;
    @media (max-width: 767.98px) {
      flex: auto;
      width: calc(50% - 5px);
      padding: 0.75rem;
    }
    &:last-child {
      @media (max-width: 767.98px) {
        width: calc(100% - 0px);
      }
    }
    h5 {
      font-size: 1rem;
    }
    .referr_user {
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        fill: var(--primaryClr);
      }
      p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
.referral_link {
  background-color: #3b5160;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px;
  @media (max-width: 767.98px) {
    margin-top: 10px !important;
  }
  h5 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 10px;
  }
  div {
    background: #7e97a7;
    border-radius: 5px;
    color: var(--blackClr);
    flex: 1 1;
    padding: 8px 15px;
  }
  .btn-primary {
    padding: 0 8px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.referr_tabs ul {
  border-bottom: 0;
  gap: 20px;
  button {
    background: #7e97a7 !important;
    border: 0 !important;
    color: #fff !important;
    padding: 8px 35px;
  }
}
.referr_table {
  color: #fff;
  thead {
    border: 0;
    th {
      background: #3b5160;
      border: 0;
    }
  }
  tbody {
    td {
      background: #e0e6e6;
      color: var(--blackClr);
      border-color: #7e97a7;
      .dropdown {
        .btn-success {
          background: #7e97a7;
          border-color: #7e97a7;
        }
      }
    }
  }
}
.heading {
  background: #3b5160;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  h4,
  h2 {
    font-size: 20px;
    margin: 0;
  }
}

.WithdrawPageSection {
  .WithDrawSection.tabs-area {
    width: 760px;
    ul {
      border: 0;
      background: #2c2f3d;
      margin: 0;
      li {
        button {
          color: var(--whiteClr) !important;
          border-radius: 0 !important;
          text-align: center;
          font-weight: 400;
          border: 1px solid #444;
          background: rgb(44, 47, 61);
          background: linear-gradient(
            100deg,
            rgba(44, 47, 61, 1) 0%,
            rgba(57, 67, 91, 1) 100%
          );
          padding: 10px !important;
          &.active {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgb(43 133 243) 0%,
              rgb(131 175 228) 100%
            ) !important;
          }
        }
      }
    }
    .tab-content {
      padding-bottom: 0 !important;

      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;
        .heading {
          display: none;
        }
        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: #7e97a7;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgba(6, 99, 177, 1) 0%,
              rgba(40, 131, 239, 1) 100%
            );
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            height: 45px;
          }
        }
        h4 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        .WithdrawTable {
          thead {
            border: 1px solid #7f898f;
            border-radius: 3px !important;
            th {
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: transparent !important;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .WithdrawRequestSection {
        ul.nav-tabs {
          margin-top: 10px;
          background-color: var(--whiteClr) !important;
          button {
            background: var(--whiteClr) !important;
            color: var(--blackClr) !important;
            text-align: center !important;
            &.active {
              background: #169f00 !important;
              color: var(--whiteClr) !important;
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.jpg")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: #7e97a7;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          padding: 10px;
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: transparent;
                th {
                  background: transparent;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          background: rgb(6, 99, 177);
          background: linear-gradient(
            100deg,
            rgba(6, 99, 177, 1) 0%,
            rgba(40, 131, 239, 1) 100%
          );
          border-radius: 5px !important;
          border: 0;
          font-weight: 400;
          height: 45px;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--headClr);
    color: var(--whiteClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background: #000;
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: #7e97a7;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}
.cust-container {
  max-width: 1179px;
  margin: 0 auto;
}
.news {
  background: linear-gradient(180deg, #2a3a43 27%, #1c282d 83%);
  color: var(--whiteClr);
  height: 25px;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  overflow: hidden;
  @media only screen and (max-width: 767.98px) {
    margin-top: 0;
  }
  .news-text {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    width: 77px;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before {
      content: "";
      height: 17px;
      width: 17px;
      margin-right: 2px;
      background: url(./assets/images/news-mic.png) no-repeat;
    }
    &:after {
      content: "";
      position: absolute;
      right: -7px;
      top: 0;
      z-index: 2;
      background-image: url(./assets/images/news-arrow.png);
      background-repeat: no-repeat;
      width: 7px;
      height: 25px;
    }
  }
  a {
    color: #6ac2ff !important;
    font-size: 12px;
    font-weight: bold;
    height: 25px;
    line-height: 25px;
    margin-right: 100px;
    align-items: center;
    cursor: pointer;
    span {
      height: 15px;
      line-height: 15px;
      font-size: 11px;
      font-style: italic;
      font-weight: normal;
      color: #1c2834;
      background: #6ac2ff;
      border-radius: 2px;
      padding: 0 5px;
      margin-right: 5px;
    }
    &:hover {
      color: #6ac2ff;
      text-decoration: underline;
    }
  }
}
.mainBanner {
  margin-bottom: 10px;
  @media only screen and (max-width: 767.98px) {
    margin-bottom: 0;
  }
  .slick-slide {
    img {
      width: 100%;
      height: auto;
    }
    .content {
      bottom: auto;
      font-size: 0.85rem;
      left: 0;
      position: absolute;
      right: auto;
      text-align: left;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      z-index: 99;
      h2 {
        @media screen and (min-width: 768px) {
          font-size: 2rem;
        }
        @media screen and (min-width: 1024px) {
          font-size: 3.5rem;
        }
        @media screen and (max-width: 767px) {
          font-size: 1.15rem;
        }
        span {
          color: var(--primaryClr);
          margin: 0 8px;
        }
      }
      p {
        @media screen and (max-width: 767px) {
          font-size: 0.7rem;
        }
      }
    }
  }
  .slick-dots {
    bottom: 7px;
    li {
      width: 15.5px;
      height: 21px;
      margin: 0 0px;
      button {
        width: 10px;
        &::before {
          color: rgba(255, 255, 255, 0.3);
          font-size: 8px;
          width: 10px;
          opacity: 1;
        }
      }
      &.slick-active {
        button {
          &::before {
            font-size: 12px;
            opacity: 1;
          }
        }
      }
    }
  }
}
.home_game_types {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  @media only screen and (max-width: 767.98px) {
    grid-gap: 1.6vw;
    padding: 0 1.6vw 1.6vw;
  }
  a {
    position: relative;
    @media only screen and (max-width: 767.98px) {
      grid-column: span 2;
    }
    &.half-width {
      grid-column: span 2;
      @media only screen and (max-width: 767.98px) {
        grid-column: span 4;
      }
    }
    .title {
      position: absolute;
      z-index: 4;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 47px;
      background-image: linear-gradient(
        270deg,
        rgba(69, 94, 104, 0) 4%,
        #000000 97%
      );
      border-bottom: 5px solid #ffb80c;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 767.98px) {
        height: 7.443vw;
      }
      h4 {
        color: var(--whiteClr);
        font-size: 18px;
        font-weight: 600;
        line-height: 1.8;
        text-indent: 10px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        @media only screen and (max-width: 767.98px) {
          font-size: 3.7333333333vw;
          text-indent: 1.3333333333vw;
        }
      }
      .play {
        background-image: url("./assets/images/play-bg-triangle.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        color: var(--blackClr);
        font-size: 14px;
        font-weight: bold;
        width: 98px;
        height: 100%;
        line-height: 42px;
        text-indent: 25px;
        @media only screen and (max-width: 767.98px) {
          width: 18.1333333333vw;
          line-height: 7.4666666667vw;
          height: 6.8vw;
          text-indent: 3.7333333333vw;
          font-size: 2.9333333333vw;
        }
        &:after {
          position: absolute;
          z-index: -1;
          bottom: 0;
          right: 0;
          width: 74px;
          height: 100%;
          background-color: #ffb80c;
          content: "";
          @media only screen and (max-width: 767.98px) {
            width: 14.9333333333vw;
            height: 6.8vw;
          }
        }
      }
    }
  }
}
.footer {
  padding: 26px 0 40px;
  margin: 0px auto;
  overflow-y: scroll;
  @media only screen and (max-width: 991.98px) {
    padding-bottom: 20vw;
  }
  .support_sec {
    display: block;
    max-width: 690px;
    margin: 0 auto 20px auto;
    padding: 5px 0px;
    border-radius: 8px;
    @media only screen and (max-width: 767.98px) {
      width: 84vw;
      margin: 0 auto 6.4vw;
    }
    .support-box {
      display: flex;
      justify-content: space-between;
      line-height: 22px;
      @media only screen and (max-width: 767.98px) {
        flex-wrap: wrap;
      }
      &:last-child {
        @media only screen and (max-width: 767.98px) {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 8px;
          width: 84vw;
          margin: 0 auto;
        }
      }
      img {
        width: 26px;
        height: auto;
        margin-right: 5px;
        @media only screen and (max-width: 767.98px) {
          width: 5.8666666667vw;
          height: 5.8666666667vw;
          margin-right: 1.3333333333vw;
          filter: brightness(0.7);
        }
        &.insta {
          width: 22px;
        }
      }
      a {
        color: rgba(0, 0, 0, 0.5);
        font-size: 13px;
        line-height: 40px;
        margin-right: 8px;
        @media only screen and (max-width: 767.98px) {
          color: rgba(0, 0, 0, 0.7);
          font-size: 2.9333333333vw;
          line-height: 6.6666666667vw;
        }
        &:nth-child(3) {
          padding-left: 10px;
          border-left: 1px solid rgba(0, 0, 0, 0.5);
        }
      }
    }
    .inner-box {
      background: var(--whiteClr);
      flex-basis: 49.277%;
      font-size: 13px;
      text-align: center;
      padding: 8px 0;
      margin-bottom: 8px;
      border: 1px solid rgba(151, 151, 151, 0.5);
      border-radius: 8px;
      @media only screen and (max-width: 767.98px) {
        flex-basis: 100%;
        border: 0;
      }
      img {
        @media only screen and (max-width: 767.98px) {
          width: 8vw;
          height: 8vw;
          margin-right: 1.0666666667vw;
        }
      }
    }
    .social-box {
      background-color: var(--whiteClr);
      flex-basis: 32.29%;
      text-align: center;
      padding: 8px 0;
      margin-bottom: 8px;
      border: 1px solid rgba(151, 151, 151, 0.5);
      border-radius: 8px;
      @media only screen and (max-width: 767.98px) {
        border: 0;
      }
    }
  }
  .power-wrap {
    background-color: rgba(0, 0, 0, 0);
    // background: rgba(255, 255, 255, 0.25);
    color: rgba(0, 0, 0, 0.6);
    max-width: 692px;
    padding: 5px 10px;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    h3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      padding-right: 7px;
      margin-right: 7px;
      margin-bottom: 0px;
      span {
        font-size: 10px;
        font-weight: normal;
        line-height: 15px;
      }
      img {
        height: 18px;
        width: 107px;
      }
    }
    .licence_embed {
      background: var(--whiteClr);
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      width: 150px;
      height: 50px;
      margin-right: 5px;
      img {
        max-width: 120px;
        height: auto;
      }
    }
    p {
      color: rgba(0, 0, 0, 0.6);
      flex: 1;
      font-size: 11px;
      line-height: 14px;
      text-align: justify;
      margin: 0;
      padding-left: 5px;
    }
  }
  .browser-wrap {
    color: rgba(0, 0, 0, 0.6);
    font-size: 11px;
    text-align: center;
    line-height: 1.365;
    img {
      width: 50px;
      height: 20px;
      opacity: 0.5;
      margin-bottom: 5px;
    }
  }
  .policy-link {
    text-align: center;
    padding-top: 4px;
    margin: 8px auto 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    @media only screen and (max-width: 767.98px) {
      border-top: 0;
    }
    li {
      display: inline-block;
      margin-bottom: 5px;
      &:after,
      &:first-child:before {
        margin: 0 9px;
        content: "-";
        color: rgba(0, 0, 0, 0.6);
      }
      &:after,
      &:first-child:before {
        margin: 0 9px;
        content: "-";
        color: rgba(0, 0, 0, 0.6);
        @media only screen and (max-width: 767.98px) {
          content: "|";
        }
      }
      a {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 15px;
        text-decoration: underline;
      }
    }
  }
  .app-link {
    color: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 18px;
    a {
      margin-bottom: 2px;
      display: inline-block;
      text-align: center;
      img {
        width: 155px;
        height: auto;
      }
    }
    p {
      color: rgba(0, 0, 0, 0.6);
      font-size: 10px;
      text-align: center;
      margin-bottom: 0px;
    }
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 46px;
        }
      }
    }
  }
  .footer-links {
    background: var(--secondaryClr);
    display: inline-block;
    margin: 20px auto 10px auto;
    padding: 0 15px;
    width: 100%;
    ul {
      text-align: center;
      padding: 15px 0;
      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        margin: 5px 15px;
        &:after {
          content: " ";
          position: absolute;
          right: -16px;
          top: 2px;
          width: 1px;
          height: 14px;
          background: var(--headClr);
        }
        &:last-child {
          margin-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: var(--whiteClr);
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.footer-menu {
  background: linear-gradient(-180deg, #243a48 20%, #172732 91%);
  z-index: 999;
  box-shadow: 0 0px 14px rgb(255 189 0 / 12%);
  ul {
    li {
      position: relative;
      &.main-nav {
        &:before {
          position: absolute;
          content: "";
          top: -4.5333333333vw;
          width: 110%;
          height: 5.3333333333vw;
          background-image: url("./assets/images/main-nav-top-img.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          pointer-events: none;
          margin-left: -0.2666666667vw;
          z-index: -1;
        }
        a {
          height: 16.8vw;
          padding-top: 1.3333333333vw;
          margin-top: -4.5333333333vw;
          img {
            width: 17.8666666667vw;
            height: 16.2666666667vw;
            margin: 0 auto;
            margin-left: -1.8666666667vw;
            z-index: 99;
          }
        }
      }
      a {
        color: var(--whiteClr);
        font-size: 3.2vw;
        text-transform: capitalize;
        text-decoration: none;
        width: 16.8vw;
        height: 13.3333333333vw;
        line-height: 1.1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.8666666667vw 2.6666666667vw 0;
        cursor: pointer;
        img {
          width: 5.8666666667vw;
          height: 5.3333333333vw;
          margin: 0 auto 0.5333333333vw;
          &.icon-home {
            width: 6.6666666667vw;
          }
        }
        span {
          width: -webkit-fill-available;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.active {
          color: var(--primaryClr);
        }
      }
    }
  }
}

.live_casino_page {
  .ezugi_iframe,
  .exchange_iframe {
    height: calc(100vh - 104px);
    overflow: hidden;
    @media (max-width: 767.98px) {
      height: calc(100vh - (14.6666666667vw + 48px));
    }
    iframe {
      @media only screen and (min-device-width: 280px) and (max-device-width: 767.98px) {
        height: calc(100vh - (14.6666666667vw + 48px)) !important;
        height: calc(
          var(--vh, 1vh) * 100 - (14.6666666667vw + 48px)
        ) !important;
      }
    }
  }
}

.MaintainSection {
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 40px;
  .MaintainSectionContent {
    width: 50%;

    .MaintainSection__title {
      font-size: 40px;
      line-height: 17px;
    }
    p.maintainword {
      text-transform: uppercase;
      color: var(--primaryClr);
      font-weight: 800;
      font-size: 50px;
      position: relative;
      margin-bottom: 70px;
      &::before {
        width: 55px;
        height: 5px;
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        left: 30%;
        margin-left: -14px;
        background-color: var(--primaryClr);
      }
      &::after {
        width: 410px;
        height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 6px;
        left: 82px;
        margin-left: -78px;
        background-color: var(--primaryClr);
      }
    }
    p.backsoon {
      font-size: 40px;
      color: #000;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
      color: var(--blackClr);
    }
  }
  .MaintainImg {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 500px;
    }
  }
}
.valuesDeposit {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--headClr);
  padding: 8px;
  border-radius: 5px;
  margin-top: 12px;
  h3 {
    margin-bottom: 0;
    margin-right: 12px;
  }
  .copyValue {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0b1e2c;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
  }
}
.qrSize {
  width: 250px;
}
.HpaySection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 350px;
  background: var(--blackClr);
  position: relative;
  margin: 30px auto;
  .HayQR {
    cursor: pointer;
    img {
      width: 30px !important;
      height: 30px !important;
      position: absolute;
      right: -14px;
      top: -16px;
      margin-top: 0 !important;
    }
  }
  img,
  canvas {
    width: 300px !important;
    height: 300px !important;
    margin-top: 10px !important;
  }
}

.banktransfer p {
  color: var(--blackClr);
}
